@import '../assets/style/shared.scss';
.Features {
  width: 100vw;

  .features-title-section {
    @extend .tp-page-layout;
    padding: 8%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5) url('../assets/background/bg-features-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-blend-mode: darken;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .features-title {
      font-family: Roboto, sans-serif;
      font-size: 80px;
      font-weight: 100;
      line-height: 1.25;
      letter-spacing: 11.2px;
      color: white;
      b {
        font-weight: 500;
        color: #ffd133;
      }
    }
    .features-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .feature-card {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #ffffff50;
        &:last-child {
          border-right: none;
        }

        .card-icon {
          width: 64px;
          height: auto;
          margin-bottom: 18px;
        }
        .card-content {
          font-size: 24px;
          white-space: pre;
          line-height: 1.5;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
  .features-detail-section {
    @extend .tp-page-layout;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb400;
    color: white;
    .left-section {
      width: 50%;
      height: 100%;
      position: relative;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8%;
      box-sizing: border-box;
      .chapter {
        width: 68%;
        position: absolute;
        top: 16%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 2px;
        b {
          font-weight: bold;
        }
      }
      .content-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-family: Roboto, sans-serif;
          font-size: 60px;
          font-weight: 500;
          line-height: 1.47;
          letter-spacing: 12px;
          text-align: left;
          color: #fff;
        }
        .underline {
          width: 60px;
          height: 1px;
          margin: 40px 0px;
          background-color: currentColor;
        }
        .sub-title {
          font-size: 24px;
          line-height: 1.5;
          letter-spacing: -0.6px;
          color: #191919;
          margin-bottom: 10px;
        }
        .content {
          font-size: 20px;
          line-height: 1.8;
          text-align: left;
          color: rgba(25, 25, 25, 0.8);
          white-space: pre-wrap;
        }
      }
    }
    .right-section {
      width: 50%;
      height: 100%;
      background-image: url('../assets/background/bg-features-2.jpg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .features-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        box-sizing: border-box;
        gap: 20px;
        padding: 20px;
        .feature-card {
          flex: 1 1 calc(100% / 3 - 40px);
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;

          .card-icon {
            width: 88px;
            height: 88px;
          }
          .card-title {
            font-size: 20px;
            line-height: 1.8;
            letter-spacing: -0.5px;
            text-align: center;
            color: #fff;
          }
          .card-content {
            opacity: 0.9;
            font-size: 16px;
            line-height: 1.75;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            white-space: normal;
          }
        }
      }
    }
  }
  @media #{$mat-gt-xl} {
    .features-title-section {
      .features-title {
        font-size: toViewPort(80);
        letter-spacing: toViewPort(11.2);
      }
      .features-group {
        .feature-card {
          .card-icon {
            width: toViewPort(64);
            margin-bottom: toViewPort(18);
          }
          .card-content {
            font-size: toViewPort(24);
          }
        }
      }
    }
    .features-detail-section {
      .left-section {
        .chapter {
          font-size: toViewPort(20);
          letter-spacing: toViewPort(2);
        }
        .content-box {
          .title {
            font-size: toViewPort(60);
            letter-spacing: toViewPort(12);
          }
          .underline {
            width: toViewPort(60);
            height: toViewPort(1);
            margin: toViewPort(40) 0px;
          }
          .sub-title {
            font-size: toViewPort(24);
            letter-spacing: toViewPort(-0.6);
            margin-bottom: toViewPort(10);
          }
          .content {
            font-size: toViewPort(20);
          }
        }
      }
      .right-section {
        .features-group {
          padding: toViewPort(20);
          .feature-card {
            .card-icon {
              width: toViewPort(88);
              height: toViewPort(88);
            }
            .card-title {
              font-size: toViewPort(20);
              letter-spacing: toViewPort(-0.5);
            }
            .card-content {
              font-size: toViewPort(16);
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .features-title-section {
      .features-title {
        font-size: 60px;
      }
      .features-group {
        .feature-card {
          .card-icon {
            width: 48px;
          }
          .card-content {
            font-size: 18px;
          }
        }
      }
    }
    .features-detail-section {
      .left-section {
        .chapter {
          font-size: 18px;
        }
        .content-box {
          .title {
            font-size: 45px;
          }
          .underline {
            width: 45px;
            margin: 30px 0px;
          }
          .sub-title {
            font-size: 16px;
          }
          .content {
            font-size: 14px;
          }
        }
      }
      .right-section {
        .features-group {
          .feature-card {
            .card-icon {
              width: 66px;
              height: 66px;
            }
            .card-title {
              font-size: 16px;
            }
            .card-content {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .features-title-section {
      padding: 4%;
      .features-title {
        font-size: 40px;
      }
      .features-group {
        .feature-card {
          .card-icon {
            width: 32px;
          }
          .card-content {
            font-size: 12px;
          }
        }
      }
    }
    .features-detail-section {
      .left-section {
        .chapter {
          font-size: 12px;
        }
        .content-box {
          .title {
            font-size: 30px;
          }
          .underline {
            width: 30px;
            margin: 20px 0px;
          }
          .sub-title {
            font-size: 12px;
          }
          .content {
            font-size: 12px;
          }
        }
      }
      .right-section {
        .features-group {
          gap: 10px;
          padding: 10px;
          .feature-card {
            flex: 1 1 calc(50% - 30px);
            .card-icon {
              width: 44px;
              height: 44px;
            }
            .card-title {
              font-size: 12px;
            }
            .card-content {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-md} {
    .features-title-section {
      height: fit-content;
      padding: 6%;

      .features-title {
        text-align: center;
      }
      .features-group {
        width: 100%;
        flex-wrap: wrap;

        .feature-card {
          width: 50%;
          flex: 1 1 calc(50% - 63px);
          padding: 25px;

          border-right: none;
          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid #ffffff50;
          }
          &:nth-child(1),
          &:nth-child(3) {
            border-right: 1px solid #ffffff50;
          }
        }
      }
    }
    .features-detail-section {
      height: fit-content;
      flex-direction: column;
      .left-section {
        width: 100%;
        height: fit-content;
        min-height: 480px;
        padding: 6%;
        box-sizing: border-box;
        .chapter {
          display: none;
        }
        .content-box {
          width: 100%;
          .title {
            margin-bottom: 100px;
          }
        }
      }
      .right-section {
        width: 100%;
        height: 50%;
        padding: 100px 0px;
        .features-group {
          .feature-card {
            flex: 1 1 calc(100% / 2 - 20px);
          }
        }
      }
    }
  }
  @media #{$mat-lt-xs} {
    .features-title-section {
      .features-title {
        font-size: 25px;
      }
      .features-group {
        .feature-card {
          flex: 1 1 100%;
          border-bottom: 1px solid #ffffff50;

          &:nth-child(1),
          &:nth-child(3) {
            border-right: 1px solid #ffffff50;
            border-right: none;
          }
        }
      }
    }
  }
}
