@import '../../assets/style/shared.scss';

.SlideAnimation {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  .slide-container {
    position: absolute;
    max-width: 100vw;
    width: 100%;
    height: 100%;

    .slide-content {
      @extend .tp-page-layout;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      list-style: none;
      padding: 0;
      & > div {
        position: relative;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        .content-box {
          position: absolute;
          top: 37%;
          left: 8%;
          font-size: 60px;
          font-weight: 300;
          letter-spacing: -1.5px;
          line-height: 1.47;
          word-break: keep-all;
          white-space: pre-wrap;
          box-sizing: border-box;
          text-align: left;
          color: #fff;
          b {
            display: inline;
            font-weight: 500;
          }
        }
      }
    }
  }
  .slide-controller {
    position: absolute;
    top: 85%;
    left: 8%;
    display: flex;
    align-items: center;
    color: white;
    z-index: 2;

    .current-page,
    .total-page {
      font-size: 16px;
    }

    .progress {
      position: relative;
      margin: 0px 14px;

      .back {
        width: 180px;
        height: 2px;
        background-color: #ffffff80;
        .front {
          position: absolute;
          top: 0;
          left: 0;
          width: 0%;
          height: 2px;
          background-color: currentColor;
          transition-duration: 1s;
          transition-timing-function: cubic-bezier(0, 1, 1, 1);
          transition-property: left;
        }
      }
    }

    .action-box {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: currentColor;
      img {
        width: 28px;
        height: 28px;
        padding: 2px;
        margin: 2px;
        color: currentColor;
        cursor: pointer;
      }
    }
  }
  @media #{$mat-gt-xl} {
    .slide-container {
      .slide-content {
        & > div {
          .content-box {
            font-size: toViewPort(60);
            letter-spacing: toViewPort(-1.5);
          }
        }
      }
    }
    .slide-controller {
      .current-page,
      .total-page {
        font-size: toViewPort(16);
      }

      .progress {
        margin: 0px toViewPort(14);

        .back {
          width: toViewPort(180);
          height: toViewPort(2);
          .front {
            height: toViewPort(2);
          }
        }
      }

      .action-box {
        margin-left: toViewPort(10);
        img {
          width: toViewPort(28);
          height: toViewPort(28);
          padding: toViewPort(2);
          margin: toViewPort(2);
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .slide-container {
      .slide-content {
        & > div {
          .content-box {
            font-size: 45px;
          }
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .slide-container {
      .slide-content {
        & > div {
          .content-box {
            font-size: 35px;
          }
        }
      }
    }
  }
  @media #{$mat-md} and (orientation: portrait) {
    height: 720px;
  }
  @media #{$mat-lg} and (orientation: portrait) {
    height: 1080px;
  }
  @media #{$mat-gt-xl} and (orientation: portrait) {
    height: calc(9 / 16 * 100vw);
  }
  @media #{$mat-lt-md} {
    .slide-container {
      .slide-content {
        & > div {
          .content-box {
            top: 35%;
            font-size: 30px;
          }
        }
      }
    }
    .slide-controller {
      .progress {
        .front {
          width: 0px;
        }
        .back {
          width: 180px;
        }
      }
    }
  }
  @media #{$mat-lt-xs} {
    .slide-container {
      .slide-content {
        & > div {
          .content-box {
            top: 30%;
          }
        }
      }
    }

    .slide-controller {
      .current-page,
      .total-page {
        font-size: 12px;
      }
      .progress {
        .back {
          width: 100px;
        }
      }
      .action-box {
        img {
          width: 20px;
          height: 20px;
          padding: 2px;
          margin: 0px;
        }
      }
    }
  }
}
