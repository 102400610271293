@import '@fontsource/noto-sans-kr/korean';
@import './assets/style/constant.scss';

@font-face {
  font-family: 'PretendardVariable';
  font-weight: normal;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 100;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 200;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 300;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 400;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 500;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 600;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 700;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 800;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'PretendardVariable';
  font-weight: 900;
  src: url('./assets/font/PretendardVariable.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  &.not-scroll {
    overflow-y: hidden;
  }

  pre,
  a {
    font-family: 'Noto Sans KR', Roboto, sans-serif;
  }
  &.not-kr {
    font-family: Roboto, sans-serif;
    pre,
    a {
      font-family: Roboto, sans-serif;
    }
  }
}
