@import '../assets/style/shared.scss';

.Popup {
  position: fixed;
  z-index: 10;
  top: 0;

  user-select: none;

  .inquiry-popup {
    width: 100vw;
    height: 100vh;

    box-sizing: border-box;

    background-color: #0000004d;

    display: flex;
    justify-content: center;
    align-items: center;

    .content-box {
      width: 1062px;
      height: 800px;
      box-sizing: border-box;

      background-color: white;

      position: relative;

      display: flex;

      .close-btn {
        position: absolute;

        right: 0;
        margin-right: 32px;
        margin-top: 32px;

        width: 24px;
        height: 24px;

        cursor: pointer;
      }

      .left-section {
        width: 660px;
        height: 100%;
        .image {
          width: 100%;
          height: 100%;
        }
      }

      .right-section {
        padding-top: 80px;
        padding-right: 40px;

        padding-bottom: 67px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-section {
          .big-text {
            font-size: 32px;
            font-family: PretendardVariable;
            line-height: 1.31;
            letter-spacing: -1.28px;
            font-weight: 800;
            color: rgba(0, 0, 0, 0.9);
          }

          .middle-text {
            font-family: PretendardVariable;
            font-size: 20px;
            line-height: 1.5;
            letter-spacing: -0.5px;
            color: #000;

            margin-top: 20px;
            margin-bottom: 16px;
          }

          .small-text {
            font-family: PretendardVariable;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: #999;
          }
        }

        .bottom-section {
          .category-box {
            display: flex;
            flex-direction: column;

            input {
              width: 360px;
              height: 24px;
              margin-top: 26px;

              font-family: PretendardVariable;
              font-size: 16px;
              line-height: 1.75;
              color: #616161;

              border: none;
              border-bottom: 1px solid #0000001a;
              outline: none;
            }

            .label-text {
              margin-top: 28px;

              font-family: PretendardVariable;
              font-size: 16px;
              line-height: 1.75;
              color: #616161;
            }

            .small-text {
              margin-top: 0px;

              font-family: PretendardVariable;
              font-size: 14px;
              line-height: 2;
              letter-spacing: -0.35px;
              color: #999;
            }

            .alert-text {
              color: #dc3232;
              font-size: 10px;
              font-family: PretendardVariable;
            }
          }

          .submit-btn {
            width: 180px;
            height: 48px;
            margin-top: 38px;

            display: flex;
            justify-content: center;
            align-items: center;

            font-family: PretendardVariable;
            font-weight: bold;
            font-size: 16px;
            color: white;
            line-height: 2.25;
            letter-spacing: 1.92px;
            background-color: black;

            cursor: pointer;
            border: none;
          }
        }
      }
    }
  }

  .brochure-popup {
    width: 100vw;
    height: 100vh;

    box-sizing: border-box;

    background-color: #0000004d;

    display: flex;
    justify-content: center;
    align-items: center;

    .content-box {
      width: 1062px;
      height: 800px;
      box-sizing: border-box;

      background-color: white;

      position: relative;

      display: flex;

      .close-btn {
        position: absolute;

        right: 0;
        margin-right: 32px;
        margin-top: 32px;

        width: 24px;
        height: 24px;

        cursor: pointer;
      }

      .left-section {
        width: 660px;
        height: 100%;
        .image {
          width: 100%;
          height: 100%;
        }
      }

      .right-section {
        padding-top: 80px;
        padding-right: 40px;
        padding-bottom: 67px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-section {
          .big-text {
            font-size: 32px;
            font-family: PretendardVariable;
            line-height: 1.31;
            letter-spacing: -1.28px;
            font-weight: 800;
            color: rgba(0, 0, 0, 0.9);
          }

          .middle-text {
            font-size: 32px;
            font-family: PretendardVariable;
            line-height: 1.31;
            letter-spacing: -1.28px;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.9);
          }
        }

        .bottom-section {
          .category-box {
            display: flex;
            flex-direction: column;

            input {
              width: 360px;
              height: 24px;
              margin-top: 26px;

              font-family: PretendardVariable;
              font-size: 16px;
              line-height: 1.75;
              color: #616161;

              border: none;
              border-bottom: 1px solid #0000001a;
              outline: none;
            }
          }

          .check-box-container {
            display: flex;
            justify-content: space-between;
            align-items: start;

            margin-top: 30px;

            .check-box {
              display: flex;

              input {
                width: 16px;
                height: 16px;
                margin: 0;

                accent-color: #191919;
                border: none;
                outline: none;
              }

              .small-text {
                font-family: PretendardVariable;
                font-size: 13px;
                line-height: 1.38;
                letter-spacing: -0.33px;

                color: #616161;

                margin-left: 4px;
              }
            }

            .lined-text {
              font-family: PretendardVariable;
              font-size: 12px;
              font-weight: 500;
              line-height: 1.5;
              letter-spacing: -0.3px;
              color: #999;
              text-decoration: underline;

              cursor: pointer;
            }
          }

          .alert-text {
            color: #dc3232;
            font-size: 10px;
            font-family: PretendardVariable;
          }

          .submit-btn {
            width: 180px;
            height: 48px;
            margin-top: 38px;

            display: flex;
            justify-content: center;
            align-items: center;

            font-family: PretendardVariable;
            font-weight: bold;
            font-size: 16px;
            color: white;
            line-height: 2.25;
            letter-spacing: 1.92px;
            background-color: black;

            cursor: pointer;
            border: none;
          }
        }
      }
    }
  }

  .private-popup {
    position: absolute;
    top: 0;
    z-index: 11;

    width: 100vw;
    height: 100vh;

    box-sizing: border-box;

    background-color: #0000004d;

    display: flex;
    justify-content: center;
    align-items: center;

    .content-box {
      width: 1062px;
      height: 800px;
      box-sizing: border-box;

      background-color: white;

      position: relative;

      display: flex;
      flex-direction: column;

      .close-btn {
        position: absolute;

        right: 0;
        margin-right: 32px;
        margin-top: 32px;

        width: 24px;
        height: 24px;

        cursor: pointer;
      }

      .top-section {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 40px;

        .big-text {
          font-family: PretendardVariable;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.78;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .bottom-section {
        border: solid 1px #0000001a;

        margin: 40px;

        width: 982px;
        flex-grow: 1;

        padding-left: 40px;
        padding-top: 37px;
        padding-right: 127px;

        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;

        overscroll-behavior: none;

        .big-text {
          font-family: PretendardVariable;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.78;
          color: #000;

          margin-top: 48px;
          margin-bottom: 16px;

          width: 815px;
        }

        .small-text {
          font-family: PretendardVariable;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.32px;
          color: #616161;

          width: 815px;

          white-space: pre-line;
        }
      }
    }
  }
}

@media #{$mat-lt-lg} {
  .Popup {
    .inquiry-popup {
      .content-box {
        width: 360px;
        height: 740px;

        .close-btn {
          right: 0;
          margin-right: 16px;
          margin-top: 12px;
        }

        .left-section {
          display: none;
        }

        .right-section {
          padding-top: 60px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 40px;

          .top-section {
            .big-text {
              font-size: 28px;

              line-height: 1.29;
              letter-spacing: -1.12px;
            }

            .middle-text {
              font-size: 18px;
              line-height: 1.78;
              letter-spacing: -0.45px;

              margin-top: 16px;
              margin-bottom: 12px;
            }

            .small-text {
              font-size: 12px;
              line-height: 1.5;
              letter-spacing: -0.3px;
            }
          }

          .bottom-section {
            .category-box {
              input {
                width: 330px;
                height: 24px;
                margin-top: 20px;

                font-size: 14px;
                line-height: 1.71;
              }

              .label-text {
                margin-top: 22px;

                font-size: 13px;
                line-height: 1.69;
              }

              .small-text {
                margin-top: 0px;

                font-size: 12px;
                line-height: 1.5;
                letter-spacing: -0.3px;
              }
            }
            .submit-btn {
              width: 324px;
              height: 48px;
              margin-top: 48px;

              font-size: 14px;
              line-height: 2.57;
              letter-spacing: 1.68px;
            }
          }
        }
      }
    }

    .brochure-popup {
      .content-box {
        width: 360px;
        height: 740px;

        .close-btn {
          right: 0;
          margin-right: 16px;
          margin-top: 12px;
        }

        .left-section {
          display: none;
        }

        .right-section {
          padding-top: 60px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 40px;

          .top-section {
            .big-text {
              font-size: 28px;

              line-height: 1.29;
              letter-spacing: -1.12px;
            }

            .middle-text {
              font-size: 28px;

              line-height: 1.29;
              letter-spacing: -1.12px;
            }
          }

          .bottom-section {
            .category-box {
              input {
                width: 330px;
                height: 24px;
                margin-top: 20px;

                font-size: 14px;
                line-height: 1.71;
              }
            }
            .submit-btn {
              width: 324px;
              height: 48px;
              margin-top: 48px;

              font-size: 14px;
              line-height: 2.57;
              letter-spacing: 1.68px;
            }
          }
        }
      }
    }

    .private-popup {
      .content-box {
        width: 360px;
        height: 740px;
        .close-btn {
          right: 0;
          margin-right: 16px;
          margin-top: 12px;
        }

        .top-section {
          margin-top: 20px;

          .big-text {
            font-size: 14px;

            line-height: 1.71;
          }
        }

        .bottom-section {
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 40px;
          margin: 0;

          width: 360px;

          border: none;

          .big-text {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.28px;

            margin-top: 32px;
            margin-bottom: 12px;

            width: 310px;
          }

          .small-text {
            font-size: 12px;
            line-height: 1.75;
            letter-spacing: -0.24px;

            width: 310px;
          }
        }
      }
    }
  }
}

@media #{$mat-lt-xs} {
  .Popup {
    .inquiry-popup {
      .content-box {
        width: 100%;
        height: 100%;

        .right-section {
          padding-top: toViewPortMobile(60);
          padding-left: toViewPortMobile(16);
          padding-right: toViewPortMobile(16);
          padding-bottom: toViewPortMobile(40);

          .top-section {
            .big-text {
              font-size: toViewPortMobile(28);

              line-height: 1.29;
              letter-spacing: toViewPortMobile(-1.12);
            }

            .middle-text {
              font-size: toViewPortMobile(18);
              line-height: 1.78;
              letter-spacing: toViewPortMobile(-0.45);

              margin-top: toViewPortMobile(16);
              margin-bottom: toViewPortMobile(12);
            }

            .small-text {
              font-size: toViewPortMobile(12);
              line-height: 1.5;
              letter-spacing: toViewPortMobile(-0.3);
            }
          }

          .bottom-section {
            .category-box {
              input {
                width: toViewPortMobile(330);
                height: toViewPortMobile(24);
                margin-top: toViewPortMobile(20);

                font-size: toViewPortMobile(14);
                line-height: 1.71;
              }

              .label-text {
                margin-top: toViewPortMobile(22);

                font-size: toViewPortMobile(13);
                line-height: 1.69;
              }

              .small-text {
                margin-top: toViewPortMobile(0);

                font-size: toViewPortMobile(12);
                line-height: 1.5;
                letter-spacing: toViewPortMobile(-0.3);
              }
            }
            .submit-btn {
              width: toViewPortMobile(324);
              height: toViewPortMobile(48);
              margin-top: toViewPortMobile(48);

              font-size: toViewPortMobile(14);
              line-height: 2.57;
              letter-spacing: toViewPortMobile(1.68);
            }
          }
        }
      }
    }

    .brochure-popup {
      .content-box {
        width: 100%;
        height: 100%;

        .right-section {
          padding-top: toViewPortMobile(60);
          padding-left: toViewPortMobile(16);
          padding-right: toViewPortMobile(16);
          padding-bottom: toViewPortMobile(40);

          .top-section {
            .big-text {
              font-size: toViewPortMobile(28);

              line-height: 1.29;
              letter-spacing: toViewPortMobile(-1.12);
            }

            .middle-text {
              font-size: toViewPortMobile(28);

              line-height: 1.29;
              letter-spacing: toViewPortMobile(-1.12);
            }
          }

          .bottom-section {
            .category-box {
              input {
                width: toViewPortMobile(330);
                height: toViewPortMobile(24);
                margin-top: toViewPortMobile(20);

                font-size: toViewPortMobile(14);
                line-height: 1.71;
              }
            }
            .submit-btn {
              width: toViewPortMobile(324);
              height: toViewPortMobile(48);
              margin-top: toViewPortMobile(48);

              font-size: toViewPortMobile(14);
              line-height: 2.57;
              letter-spacing: toViewPortMobile(1.68);
            }
          }
        }
      }
    }

    .private-popup {
      .content-box {
        width: 100%;
        height: 100%;

        .top-section {
          margin-top: toViewPortMobile(20);

          .big-text {
            font-size: toViewPortMobile(14);
          }
        }

        .bottom-section {
          padding-left: toViewPortMobile(16);
          padding-right: toViewPortMobile(16);
          padding-top: toViewPortMobile(40);

          width: toViewPortMobile(360);

          .big-text {
            font-size: toViewPortMobile(14);
            letter-spacing: toViewPortMobile(-0.28);

            margin-top: toViewPortMobile(32);
            margin-bottom: toViewPortMobile(12);

            width: toViewPortMobile(310);
          }

          .small-text {
            font-size: toViewPortMobile(12);
            letter-spacing: toViewPortMobile(-0.24);

            width: toViewPortMobile(310);
          }
        }
      }
    }
  }
}
