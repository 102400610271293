@import '../assets/style/shared.scss';

.Solutions {
  @extend .tp-page-layout;
  position: relative;
  background: url('../assets/background/bg-solutions.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 0 8%;
  box-sizing: border-box;

  .solutions-title {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 4.8px;
    text-align: center;
    color: #fff;
  }

  .solution-group {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;

    .solution-area {
      all: unset;
      position: relative;
      height: 100%;
      width: 20%;
      border-left: 1px solid #ffffff19;
      border-right: 1px solid #ffffff19;
      border-collapse: collapse;
      margin-left: -1px;
      cursor: pointer;
      &:hover {
        background-color: #ffffff19;
        border-left: 1px solid #ffffff80;
        border-right: 1px solid #ffffff80;
      }
      &:first-child {
        margin-left: 0px;
      }

      .area-inner {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .solution-number {
          font-family: Roboto, sans-serif;
          font-size: 44px;
          font-weight: 100;
          font-style: italic;
          line-height: 0.91;
          text-align: center;
          color: #ffd133;
          margin-bottom: 40px;
        }
        .solution-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .solution-title {
            font-family: Roboto, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.67;
            letter-spacing: 3.6px;
            text-align: center;
            color: #fff;
          }
          .solution-content {
            font-size: 16px;
            line-height: 1.75;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            white-space: normal;
          }
          .solution-icon {
            margin-top: 40px;
          }
        }
      }
    }
  }
  @media #{$mat-gt-xl} {
    .solutions-title {
      font-size: toViewPort(48);
      letter-spacing: toViewPort(4.8);
    }

    .solution-group {
      .solution-area {
        .area-inner {
          height: toViewPort(200);
          .solution-number {
            font-size: toViewPort(44);
            margin-bottom: toViewPort(40);
          }
          .solution-content {
            .solution-title {
              font-size: toViewPort(24);
              letter-spacing: toViewPort(3.6);
            }
            .solution-content {
              font-size: toViewPort(16);
            }
            .solution-icon {
              margin-top: toViewPort(40);
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .solutions-title {
      font-size: 36px;
    }

    .solution-group {
      .solution-area {
        .area-inner {
          .solution-number {
            font-size: 33px;
          }
          .solution-content {
            .solution-title {
              font-size: 18px;
            }
            .solution-content {
              font-size: 14px;
            }
            .solution-icon {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    padding: 0 4%;
    .solutions-title {
      font-size: 24px;
    }
  }
  @media #{$mat-lt-md} {
    padding: 0%;

    .solutions-title {
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
      padding: 6%;
      padding-top: 18%;
      text-align: left;
    }
    .solution-group {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .solution-area {
        position: relative;
        height: 180px;
        width: 100% !important;
        border-left: none;
        border-right: none;
        margin-left: 0px;
        border-top: 1px solid #ffffff19;
        border-bottom: 1px solid #ffffff19;
        margin-top: -1px;
        &:hover {
          border-left: none;
          border-right: none;
          border-top: 1px solid #ffffff80;
          border-bottom: 1px solid #ffffff80;
        }
        &:first-child {
          margin-top: 0px;
        }

        .area-inner {
          position: relative;
          top: 50%;
          left: 0%;
          transform: translateY(-50%);
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .solution-number {
            margin: 6%;
            margin-right: 12%;
          }
          .solution-content {
            align-items: flex-start;
            .solution-title {
              text-align: left;
            }
            .solution-content {
              text-align: left;
              width: 50%;
            }
            .solution-icon {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
