@import '../assets/style/shared.scss';

.Hero {
  position: relative;

  .user-controller {
    position: absolute;
    top: 58%;
    left: 8%;
    display: flex;
    align-items: center;
    z-index: 2;

    a {
      all: unset;
      opacity: 0.9;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.25;
      letter-spacing: 1.92px;
      margin: 60px 0px;
      color: #fff;
      &:first-child {
        margin-right: 20px;
      }
      .link-button {
        all: unset;
        width: 160px;
        height: 52px;

        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #ffffff80;
        cursor: pointer;
        &:hover {
          border: solid 1px #ffffff;
        }
      }
    }
  }
  @media #{$mat-gt-xl} {
    .user-controller {
      a {
        font-size: toViewPort(16);
        letter-spacing: toViewPort(1.92);
        margin: toViewPort(60) 0px;
        &:first-child {
          margin-right: toViewPort(20);
        }
        .link-button {
          width: toViewPort(160);
          height: toViewPort(52);
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .user-controller {
      a {
        font-size: 12px;
        margin: 40px 0px;
        .link-button {
          width: 130px;
          height: 40px;
        }
      }
    }
  }
  @media #{$mat-lt-md} {
    .user-controller {
      top: 54%;

      a {
        font-size: 10px;
        .link-button {
          width: 130px;
          height: 37px;
        }
      }
    }
  }
  @media #{$mat-lt-xs} {
    .user-controller {
      a {
        .link-button {
          width: 110px;
          height: 42px;
        }
      }
    }
  }
}
