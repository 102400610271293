@import '../assets/style/shared.scss';

.PopupPage {
  width: 100vw;
  height: 100vh;

  min-height: 850px;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  .inquiry-popup {
    box-sizing: border-box;

    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    height: 792px;

    .top-section {
      margin-bottom: 40px;

      .big-text {
        font-size: 32px;
        font-family: PretendardVariable;
        line-height: 1.31;
        letter-spacing: -1.28px;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.9);
      }

      .middle-text {
        font-family: PretendardVariable;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: -0.5px;
        color: #000;

        margin-top: 20px;
        margin-bottom: 16px;
      }

      .small-text {
        font-family: PretendardVariable;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.35px;
        color: #999;
      }
    }

    .bottom-section {
      .category-box {
        display: flex;
        flex-direction: column;

        input {
          width: 680px;
          height: 24px;
          margin-top: 26px;

          font-family: PretendardVariable;
          font-size: 16px;
          line-height: 1.75;
          color: #616161;

          border: none;
          border-bottom: 1px solid #0000001a;
          outline: none;
        }

        .label-text {
          margin-top: 28px;

          font-family: PretendardVariable;
          font-size: 16px;
          line-height: 1.75;
          color: #616161;
        }

        .small-text {
          margin-top: 0px;

          font-family: PretendardVariable;
          font-size: 14px;
          line-height: 2;
          letter-spacing: -0.35px;
          color: #999;
        }

        textarea {
          width: 680px;
          height: 138px;

          resize: none;
          outline: none;

          border: 1px solid #0000001a;

          padding: 20px;
          box-sizing: border-box;

          margin-top: 5px;

          font-family: PretendardVariable;
          font-size: 16px;
          line-height: 1.63;
          letter-spacing: -0.32px;
          color: #000;
        }

        .input-box {
          display: flex;
          justify-content: space-between;

          b {
            color: #ff5500;
            font-weight: normal;
          }
        }
      }

      .alert-text {
        color: #dc3232;
        font-size: 10px;
        font-family: PretendardVariable;
      }

      .submit-btn {
        width: 180px;
        height: 48px;
        margin-top: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-family: PretendardVariable;
        font-weight: bold;
        font-size: 16px;
        color: white;
        line-height: 2.25;
        letter-spacing: 1.92px;
        background-color: black;

        cursor: pointer;
        border: none;
      }
    }
  }
}

@media #{$mat-lt-md} {
  .PopupPage {
    .inquiry-popup {
      height: 100%;

      padding: 16px;
      padding-top: 60px;
      padding-bottom: 40px;

      .top-section {
        .big-text {
          font-size: 28px;

          line-height: 1.29;
          letter-spacing: -1.12px;
        }

        .middle-text {
          font-size: 18px;
          line-height: 1.78;
          letter-spacing: -0.45px;

          margin-top: 16px;
          margin-bottom: 12px;
        }

        .small-text {
          font-size: 12px;
          line-height: 1.5;
          letter-spacing: -0.3px;
        }
      }

      .bottom-section {
        .category-box {
          input {
            width: 330px;
            height: 24px;
            margin-top: 20px;

            font-size: 14px;
            line-height: 1.71;
          }

          .label-text {
            margin-top: 22px;

            font-size: 13px;
            line-height: 1.69;
          }

          .small-text {
            margin-top: 0px;

            font-size: 12px;
            line-height: 1.5;
            letter-spacing: -0.3px;
          }

          textarea {
            width: 330px;
            height: 210px;

            padding: 16px;

            margin-top: 5px;

            font-size: 14px;
            letter-spacing: -0.28px;
          }
        }
        .submit-btn {
          width: 324px;
          height: 48px;
          margin-top: 48px;

          font-size: 14px;
          line-height: 2.57;
          letter-spacing: 1.68px;
        }
      }
    }
  }
}

@media #{$mat-lt-xs} {
  .PopupPage {
    .inquiry-popup {
      height: 100%;

      padding: toViewPortMobile(16);
      padding-top: toViewPortMobile(60);
      padding-bottom: toViewPortMobile(40);

      .top-section {
        .big-text {
          font-size: toViewPortMobile(28);

          line-height: 1.29;
          letter-spacing: toViewPortMobile(-1.12);
        }

        .middle-text {
          font-size: toViewPortMobile(18);
          line-height: 1.78;
          letter-spacing: toViewPortMobile(-0.45);

          margin-top: toViewPortMobile(16);
          margin-bottom: toViewPortMobile(12);
        }

        .small-text {
          font-size: toViewPortMobile(12);
          line-height: 1.5;
          letter-spacing: toViewPortMobile(-0.3);
        }
      }

      .bottom-section {
        .category-box {
          input {
            width: toViewPortMobile(330);
            height: toViewPortMobile(24);
            margin-top: toViewPortMobile(20);

            font-size: toViewPortMobile(14);
            line-height: 1.71;
          }

          .label-text {
            margin-top: toViewPortMobile(22);

            font-size: toViewPortMobile(13);
            line-height: 1.69;
          }

          .small-text {
            margin-top: toViewPortMobile(0);

            font-size: toViewPortMobile(12);
            line-height: 1.5;
            letter-spacing: toViewPortMobile(-0.3);
          }

          textarea {
            width: toViewPortMobile(330);
            height: toViewPortMobile(210);

            padding: toViewPortMobile(16);

            margin-top: toViewPortMobile(5);

            font-size: toViewPortMobile(14);
            letter-spacing: toViewPortMobile(-0.28);
          }
        }
        .submit-btn {
          width: toViewPortMobile(324);
          height: toViewPortMobile(48);
          margin-top: toViewPortMobile(48);

          font-size: toViewPortMobile(14);
          line-height: 2.57;
          letter-spacing: toViewPortMobile(1.68);
        }
      }
    }
  }
}
