@import '../assets/style/shared.scss';

footer.Footer {
  width: 100%;
  background-color: #101010;
  padding: 4% 8%;
  box-sizing: border-box;
  color: #ffffff;

  .footer-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 110px;

    .footer-top-left {
      .info {
        font-size: 16px;
        line-height: 1.75;
        text-align: left;
        color: #fff;
        label {
          opacity: 0.5;
          margin-right: 8px;
        }
        span {
          opacity: 0.3;
        }
      }
      & > .info {
        margin-bottom: 8px;
      }
      .info-box {
        display: flex;
        align-items: center;
        .info {
          margin-right: 20px;
          padding-right: 20px;
          line-height: 0.75;
          border-right: 1px solid #ffffff80;

          &:last-child {
            margin-right: none;
            padding-right: none;
            border-right: none;
          }
        }
      }
    }
    .footer-top-right {
      .subscribe-button {
        all: unset;
        button {
          all: unset;
          height: 52px;
          padding: 0px 20px;
          font-size: 16px;
          border: solid 1px #ffffff80;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition-duration: 0.5s;
          transition-property: color;

          &:hover {
            background-color: white;
            color: #101010;
          }
        }
      }
    }
    .Remote {
      all: unset;
      width: 52px;
      height: 52px;
      position: absolute;
      bottom: 0;
      right: -104px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      background-color: #ffc500;
      border: 1px solid transparent;

      cursor: pointer;
      z-index: 10;
      &:hover {
        border: 1px solid white;
      }
      &.fix {
        position: fixed;
        bottom: 52px;
        right: 52px;
        background-color: white;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        i {
          font-size: 16px;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copyright {
      opacity: 0.2;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      color: #fff;
    }

    .link-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        all: unset;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #ffffff80;
        margin-left: 20px;
        line-height: 1;

        &:first-child {
          margin-left: 0px;
        }

        img {
          max-width: 20px;
          max-height: 20px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  @media #{$mat-gt-xl} {
    .footer-top {
      margin-bottom: toViewPort(110);
      .footer-top-left {
        .info {
          font-size: toViewPort(16);
          label {
            margin-right: toViewPort(8);
          }
        }
        & > .info {
          margin-bottom: toViewPort(8);
        }
        .info-box {
          .info {
            margin-right: toViewPort(20);
            padding-right: toViewPort(20);
          }
        }
      }
      .footer-top-right {
        .subscribe-button {
          button {
            height: toViewPort(52);
            padding: 0px toViewPort(20);
            font-size: toViewPort(16);
          }
        }
      }
      .Remote {
        width: toViewPort(52);
        height: toViewPort(52);
        right: toViewPort(-104);

        a {
          i {
            font-size: toViewPort(16);
          }
        }
      }
    }
    .footer-bottom {
      .copyright {
        font-size: toViewPort(14);
      }
      .link-container {
        a {
          font-size: toViewPort(16);
          margin-left: toViewPort(20);
          img {
            max-width: toViewPort(20);
            max-height: toViewPort(20);
          }
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .footer-top {
      margin-bottom: 90px;
      .footer-top-left {
        .info {
          font-size: 14px;
        }
        .info-box {
          .info {
            margin-right: 15px;
            padding-right: 15px;
          }
        }
      }
      .footer-top-right {
        .subscribe-button {
          button {
            height: 45px;
            font-size: 14px;
          }
        }
      }
      .Remote {
        width: 45px;
        height: 45px;
        top: calc(50% - 22.5px);
        right: -90px;

        &.fix {
          top: calc(100% - 90px);
          right: 45px;
        }

        a {
          i {
            font-size: 14px;
          }
        }
      }
    }
    .footer-bottom {
      .copyright {
        font-size: 12px;
      }
      .link-container {
        a {
          font-size: 15px;

          img {
            max-width: 15px;
            max-height: 15px;
          }
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .footer-top {
      margin-bottom: 60px;
      .footer-top-left {
        .info {
          font-size: 12px;
        }
        .info-box {
          .info {
            margin-right: 10px;
            padding-right: 10px;
          }
        }
      }
      .footer-top-right {
        .subscribe-button {
          button {
            height: 30px;
            font-size: 12px;
          }
        }
      }
      .Remote {
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
        right: -60px;
        &.fix {
          top: calc(100% - 60px);
          right: 30px;
        }
        a {
          i {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media #{$mat-lt-md} {
    padding: 4%;
    .footer-top {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: flex-start;

      .footer-top-left {
        .info {
          line-height: 1;
        }
        .info-box {
          flex-direction: column;
          align-items: flex-start;
          .info {
            margin-right: none;
            padding-right: none;
            border-right: none;
            margin: 6px 0px;
          }
        }
      }
      .footer-top-right {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .Remote {
          position: relative;
          top: 0;
          right: 0;
        }
      }
    }

    .footer-bottom {
      flex-direction: column-reverse;
      justify-content: flex-start;

      .copyright {
        width: 100%;
        text-align: left;
      }

      .link-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
      }
    }
  }
}
