@import '../assets/style/shared.scss';

.Introduction {
  .introduction-main {
    @extend .tp-page-layout;
    position: relative;
    background: url('../assets/background/bg-introduction.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 8% 16%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .introduction-title {
      font-family: Roboto, sans-serif;
      font-size: 60px;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 6px;
      text-align: left;
      color: #fff;
      margin-bottom: 40px;
      b {
        font-weight: 500;
      }
    }
    .introduction-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .introduction-card {
        flex: 1 1 0;
        position: relative;
        height: 100%;
        .card-container {
          position: relative;
          width: 100%;
          height: calc(100% - 56px);
          box-sizing: border-box;
          background-color: white;
          padding: 0% 56px;
          margin-top: 56px;
          border-top: 1px solid white;
          border-bottom: 1px solid white;

          &:before {
            content: '';
            width: 100%;
            height: 0;
            box-sizing: border-box;
            position: absolute;
            top: -56px;
            left: 0;
            border-bottom: 56px solid white;
            border-right: 56px solid transparent;
          }
          &:after {
            content: '';
            width: 100%;
            height: 0;
            box-sizing: border-box;
            position: absolute;
            bottom: -56px;
            left: 0;
            border-top: 56px solid white;
            border-left: 56px solid transparent;
          }

          .card-tag {
            display: inline-block;
            border-radius: 8px 8px 0px 8px;
            padding: 5px 10px;
            margin-bottom: 5px;
            color: white;
          }
          .card-title {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.79;
            letter-spacing: -0.7px;
            text-align: left;
            color: #191919;
          }
          .horizontal-line {
            width: 100%;
            height: 1px;
            margin: 28px 0px;
            opacity: 0.2;
            background-color: #000;
          }
          .target {
            .card-target {
              opacity: 0.6;
              font-size: 18px;
              line-height: 1.78;
              letter-spacing: -0.45px;
              text-align: left;
              color: #191919;
              word-break: keep-all;
            }
          }
          .benefit {
            margin-top: 40px;
            .card-benefit {
              font-size: 20px;
              line-height: 1.6;
              text-align: left;
              color: #191919;
              word-break: keep-all;
            }
          }
        }
        &.introduction-card-1 {
          .card-tag {
            background-color: $color-SaaS;
          }
          .card-target b {
            color: $color-SaaS;
          }
          .card-benefit b {
            color: $color-SaaS;
          }
        }
        &.introduction-card-2 {
          .card-tag {
            background-color: $color-Solution;
          }
          .card-target b {
            color: $color-Solution;
          }
          .card-benefit b {
            color: $color-Solution;
          }
        }
      }
    }
  }
  .introduction-scenario {
    padding: 8% 16%;
    box-sizing: border-box;
    img {
      width: 100%;
      height: auto;
    }
  }
  @media #{$mat-gt-xl} {
    .introduction-main {
      .introduction-title {
        font-size: toViewPort(60);
        letter-spacing: toViewPort(6);
        margin-bottom: toViewPort(40);
      }
      .introduction-group {
        gap: toViewPort(20);

        .introduction-card {
          .card-container {
            // height: calc(100% - toViewPort(56));
            padding: 0% toViewPort(56);
            margin-top: toViewPort(56);

            &:before {
              top: toViewPort(-56);
              border-bottom: toViewPort(56) solid white;
              border-right: toViewPort(56) solid transparent;
            }
            &:after {
              bottom: toViewPort(-56);
              border-top: toViewPort(56) solid white;
              border-left: toViewPort(56) solid transparent;
            }

            .card-tag {
              border-radius: toViewPort(8) toViewPort(8) 0px toViewPort(8);
              padding: toViewPort(5) toViewPort(10);
              margin-bottom: toViewPort(5);
            }
            .card-title {
              font-size: toViewPort(28);
              letter-spacing: toViewPort(-0.7);
            }
            .horizontal-line {
              height: toViewPort(1);
              margin: toViewPort(28) 0px;
            }
            .target {
              .card-target {
                font-size: toViewPort(18);
                letter-spacing: toViewPort(-0.45);
              }
            }
            .benefit {
              margin-top: toViewPort(40);
              .card-benefit {
                font-size: toViewPort(20);
              }
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .introduction-main {
      padding: 8%;
      .introduction-title {
        font-size: 45px;
        margin-bottom: 30px;
      }
      .introduction-group {
        gap: 20px;

        .introduction-card {
          .card-container {
            .card-title {
              font-size: 24px;
            }
            .horizontal-line {
              margin: 18px 0px;
            }
            .target {
              .card-target {
                font-size: 14px;
              }
            }
            .benefit {
              margin-top: 30px;
              .card-benefit {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .introduction-main {
      padding: 8%;
      .introduction-title {
        font-size: 30px;
        margin-bottom: 20px;
      }
      .introduction-group {
        gap: 10px;

        .introduction-card {
          .card-container {
            height: calc(100% - 28px);
            padding: 0% 28px;
            margin-top: 28px;

            &:before {
              top: -28px;
              border-bottom: 28px solid white;
              border-right: 28px solid transparent;
            }
            &:after {
              bottom: -28px;
              border-top: 28px solid white;
              border-left: 28px solid transparent;
            }

            .card-title {
              font-size: 14px;
            }
            .horizontal-line {
              margin: 14px 0px;
            }
            .target {
              .card-target {
                font-size: 11px;
              }
            }
            .benefit {
              margin-top: 20px;
              .card-benefit {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
    .introduction-scenario {
      padding: 8%;
    }
  }
  @media #{$mat-lt-md} {
    .introduction-main {
      padding: 12% 6%;
      .introduction-group {
        display: flex;
        flex-direction: column;
        gap: 47px;

        .introduction-card {
          width: 100%;
        }
      }
    }
    .introduction-scenario {
      position: relative;
      padding: 12% 6%;
      display: flex;
      align-items: center;

      .scenario-wrapper {
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
        img {
          width: auto;
          height: 200px;
        }
      }
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        font-size: 20px;
        color: #00000080;
        background-color: white;
        border: 1px solid #00000040;
        &.left {
          left: 0;
          right: none;
        }
        &.right {
          left: none;
          right: 0;
        }
      }
    }
  }
  @media #{$mat-lt-xs} {
    .introduction-main {
      .introduction-title {
        font-size: 20px;
      }
    }
  }
}
