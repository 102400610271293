@import '../../assets/style/shared.scss';

.MobileMenu {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 0px;
  .background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    clip-path: circle(30px at 32.5px 999.5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .menu-header {
      width: 100%;
      height: 60px;
      background-color: #191919;
      display: flex;
      align-items: center;
      .language {
        color: white;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        line-height: 1.21;
        padding: 0px 16px;
        border-right: 1px solid #ffffff30;
        &.active {
          color: #ffd133;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      box-sizing: border-box;
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        margin: 0px 20px;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid #19191919;
        color: #191919;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          span {
            font-family: Roboto, sans-serif;
            display: inline-block;
            padding: 20px 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .menu-footer {
      width: 100%;
      height: 60px;
      padding: 21px;
      box-sizing: border-box;
      border-top: 1px solid #19191919;
      display: flex;
      align-items: center;
      a {
        all: unset;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #00000080;
        margin-left: 20px;
        line-height: 1;

        &:first-child {
          margin-left: 0px;
        }

        img {
          max-width: 22px;
          max-height: 22px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  .m-menu-button {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: white;
    background: transparent;
  }
  .m-nav-button {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 60px;
    top: 16px;
    width: 36px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid #ffffff40;
    color: white;
    border-radius: 50%;
    border-radius: 11px;
    background: transparent;
  }

  &.open {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .m-nav-button {
      display: none;
    }
  }
  @media #{$mat-lt-md} {
    display: block;
  }
}
