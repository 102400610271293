@import '../../assets/style/shared.scss';

.LanguageToggleMenu {
  position: relative;
  margin-left: 44px;
  .m-language-button {
    all: unset;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: white;
    background: transparent;
  }
  .language-list {
    position: absolute;
    left: 0px;
    top: 72px;
    transform: translateX(-50%);
    margin: 0;
    padding: 0;
    width: 100px;
    box-sizing: border-box;
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style: none;
      margin: 0px 16px;
      font-size: 16px;
      cursor: pointer;
      border-bottom: 1px solid #19191919;
      color: white;
      background-color: #19191980;
      span {
        font-family: Roboto, sans-serif;
        display: inline-block;
        padding: 10px 0px;
        width: 100px;
        height: 100%;
        text-align: center;
      }
    }
  }
  @media #{$mat-lt-md} {
    margin-right: 44px;
  }
}
