@import '../assets/style/shared.scss';

header.Header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108px;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  padding: 40px 8%;
  box-sizing: border-box;
  user-select: none;

  .logo {
    height: 29px;
    width: auto;
  }
  .nav-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    all: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;

    .nav-item {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      padding: 0px 28px;
      line-height: 1.19;
      letter-spacing: 1.6px;
      text-align: left;
      color: #fff;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        all: unset;
        cursor: pointer;
      }
      &.nav-item-translate {
        padding: 6px;
        margin-left: 28px;
        cursor: pointer;
      }
    }
  }
  @media #{$mat-gt-xl} {
    height: toViewPort(108);
    padding: toViewPort(40) 8%;

    .logo {
      height: toViewPort(29);
      width: auto;
    }
    .nav {
      .nav-item {
        font-size: toViewPort(16);
        padding: 0px toViewPort(28);
        letter-spacing: toViewPort(1.6);
        &.nav-item-translate {
          padding: toViewPort(6);
          margin-left: toViewPort(28);
          font-size: toViewPort(14);
          border-radius: toViewPort(20);
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    height: 68px;
    .logo {
      height: 20px;
    }
    .nav {
      .nav-item {
        font-size: 12px;
        padding: 0px 12px;
        &.nav-item-translate {
          padding: 6px;
          margin-left: 14px;
          font-size: 12px;
        }
      }
    }
  }
  @media #{$mat-lt-md} {
    height: 60px;
    box-sizing: border-box;
    padding: 0px 16px;

    .nav {
      display: none;
    }
  }
}
