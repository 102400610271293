@import '../assets/style/shared.scss';
.About {
  width: 100vw;
  .about-intro-section {
    @extend .tp-page-layout;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb400;
    color: white;

    .left-section {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 8%;
      box-sizing: border-box;
      .chapter {
        width: 68%;
        position: absolute;
        top: 16%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 2px;
        b {
          font-weight: 500;
        }
      }
      .content-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-size: 60px;
          font-weight: 300;
          line-height: 1.47;
          letter-spacing: -1.5px;
          text-align: left;
          color: #fff;
          white-space: normal;
          b {
            font-weight: 500;
          }
        }
        .underline {
          width: 60px;
          height: 1px;
          margin: 40px 0px;
          background-color: currentColor;
        }
        .content {
          width: 100%;
          font-size: 20px;
          line-height: 1.8;
          text-align: left;
          color: rgba(25, 25, 25, 0.8);
          word-break: keep-all;
          white-space: normal;
        }
      }
    }
    .right-section {
      width: 50%;
      height: 100%;
      background-image: url('../assets/background/bg-about.jpg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
  .about-possible-section {
    width: 100vw;
    padding: 8%;
    box-sizing: border-box;

    .group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      .card {
        flex: 1 1 calc(25% - 20px);
        gap: 6%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card-number {
          width: 100%;
          font-family: Roboto, sans-serif;
          font-size: 28px;
          font-weight: bold;
          font-style: italic;
          line-height: 1.29;
          text-align: left;
          align-items: flex-start;
          margin-bottom: 10px;
          color: #ffd133;
        }
        .card-icon {
          width: 46px;
          height: auto;
        }
        .card-title {
          font-size: 28px;
          line-height: 1.79;
          letter-spacing: -0.7px;
          color: #191919;
        }
        .vertical-line {
          width: 1px;
          height: 24px;
          background-color: #000;
        }
        .card-content {
          font-size: 16px;
          line-height: 1.75;
          text-align: center;
          color: rgba(25, 25, 25, 0.6);
          white-space: normal;
        }
      }
    }
  }
  .about-architecture-section {
    width: 100vw;
    padding: 8%;
    box-sizing: border-box;
    background-image: url('../assets/background/bg-architecture.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .architecture-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;
      gap: 8%;
      box-sizing: border-box;

      .title-box {
        font-family: Roboto, sans-serif;
        font-size: 60px;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: 12px;
        text-align: left;
        color: #fff;
        border-left: 2px solid #ffd133;
        padding-left: 48px;
        b {
          font-weight: 500;
        }
      }
      .content-box {
        font-size: 20px;
        line-height: 1.8;
        text-align: left;
        color: rgba(255, 255, 255, 0.9);
        white-space: pre-wrap;
      }
    }

    .architecture-main {
      background-color: white;
      padding: 100px;
      margin: 0 8%;
      box-sizing: border-box;
      .architecture-image {
        width: 100%;
        height: auto;
      }
    }
    .architecture-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 100px;
      padding-top: 0px;
      margin: 0 8%;
      margin-top: -1px;
      gap: 4%;
      background-color: white;
      .description-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .description-title-box {
          width: 100%;
          display: flex;
          align-items: center;
          text-align: left;
          font-size: 24px;
          line-height: 1.5;
          letter-spacing: -0.6px;
          color: #191919;
          margin-bottom: 10px;
          .title-number {
            opacity: 0.9;
            font-family: Roboto, sans-serif;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.56;
            color: #ffc500;
            margin-right: 10px;
          }
        }
        .description-content-box {
          font-size: 16px;
          line-height: 1.75;
          text-align: left;
          color: rgba(25, 25, 25, 0.6);
          border-left: 1px solid #00000019;
          margin-left: 11px;
          padding-left: 21px;
        }
      }
    }
  }
  @media #{$mat-gt-xl} {
    .about-intro-section {
      .left-section {
        .chapter {
          font-size: toViewPort(20);
          letter-spacing: toViewPort(2);
        }
        .content-box {
          .title {
            font-size: toViewPort(60);
            letter-spacing: toViewPort(-1.5);
          }
          .underline {
            width: toViewPort(60);
            height: toViewPort(1);
            margin: toViewPort(40) 0px;
          }
          .content {
            font-size: toViewPort(20);
          }
        }
      }
    }
    .about-possible-section {
      .group {
        .card {
          .card-number {
            font-size: toViewPort(28);
            margin-bottom: toViewPort(10);
          }
          .card-icon {
            width: toViewPort(46);
          }
          .card-title {
            font-size: toViewPort(28);
            letter-spacing: toViewPort(-0.7);
          }
          .vertical-line {
            width: toViewPort(1);
            height: toViewPort(24);
          }
          .card-content {
            font-size: toViewPort(16);
          }
        }
      }
    }
    .about-architecture-section {
      .architecture-header {
        margin-bottom: toViewPort(100);

        .title-box {
          font-size: toViewPort(60);
          letter-spacing: toViewPort(12);
          border-left: toViewPort(2) solid #ffd133;
          padding-left: toViewPort(48);
        }
        .content-box {
          font-size: toViewPort(20);
        }
      }

      .architecture-main {
        padding: toViewPort(100);
      }
      .architecture-footer {
        padding: toViewPort(100);
        .description-box {
          .description-title-box {
            font-size: toViewPort(24);
            letter-spacing: toViewPort(-0.6);
            margin-bottom: toViewPort(10);
            .title-number {
              font-size: toViewPort(18);
              margin-right: toViewPort(10);
            }
          }
          .description-content-box {
            font-size: toViewPort(16);
            margin-left: toViewPort(11);
            padding-left: toViewPort(21);
          }
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .about-intro-section {
      .left-section {
        .chapter {
          font-size: 15px;
        }
        .content-box {
          .title {
            font-size: 45px;
          }
          .underline {
            width: 50px;
            margin: 30px 0px;
          }
          .content {
            font-size: 15px;
          }
        }
      }
    }
    .about-possible-section {
      .group {
        .card {
          .card-number {
            font-size: 24px;
          }
          .card-icon {
            width: 40px;
          }
          .card-title {
            font-size: 24px;
          }
          .vertical-line {
            height: 20px;
          }
          .card-content {
            font-size: 14px;
          }
        }
      }
    }
    .about-architecture-section {
      .architecture-header {
        margin-bottom: 50px;
        .title-box {
          font-size: 40px;
        }
        .content-box {
          font-size: 16px;
        }
      }
      .architecture-main {
        padding: 50px;
      }
      .architecture-footer {
        padding: 50px;
        padding-top: 0px;

        .description-box {
          .description-title-box {
            font-size: 22px;
            .title-number {
              font-size: 18px;
            }
          }
          .description-content-box {
            font-size: 14px;
            margin-left: 11px;
            padding-left: 22px;
          }
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .about-intro-section {
      .left-section {
        .chapter {
          font-size: 12px;
        }
        .content-box {
          .title {
            font-size: 35px;
          }
          .underline {
            width: 40px;
            margin: 20px 0px;
          }
          .content {
            font-size: 12px;
          }
        }
      }
    }
    .about-possible-section {
      .group {
        .card {
          flex: 1 1 calc(50% - 20px);
        }
      }
    }
    .about-architecture-section {
      .architecture-header {
        margin-bottom: 30px;
        .title-box {
          padding-left: 20px;
          font-size: 25px;
        }
        .content-box {
          font-size: 12px;
        }
      }
      .architecture-main {
        padding: 50px;
        margin: 0;
      }
      .architecture-footer {
        padding: 50px;
        padding-top: 0px;
        margin: 0;
        .description-box {
          .description-title-box {
            font-size: 20px;
            .title-number {
              font-size: 16px;
            }
          }
          .description-content-box {
            font-size: 12px;
            margin-left: 8px;
            padding-left: 16px;
          }
        }
      }
    }
  }
  @media #{$mat-lt-md} {
    .about-intro-section {
      flex-direction: column;

      .left-section {
        width: 100%;
        height: 50%;
        padding: 6%;
        box-sizing: border-box;
        .chapter {
          display: none;
        }
        .content-box {
          width: 75%;
          .title {
            margin-bottom: 100px;
          }
        }
      }
      .right-section {
        width: 100%;
        height: 50%;
        min-height: 480px;
      }
    }
    .about-architecture-section {
      padding: 0;

      .architecture-header {
        padding: 6%;
        padding-top: 18%;
        padding-bottom: 0;
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .title-box {
          border-left: none;
          padding-left: 0px;
        }
      }

      .architecture-main {
        background-color: transparent;
        padding: 6% 12%;
        padding-bottom: 12%;
      }
      .architecture-footer {
        padding: 12% 6%;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
  @media #{$mat-lt-sm} {
    .about-possible-section {
      .group {
        .card {
          flex: 1 1 100%;
        }
      }
    }
  }
  @media #{$mat-lt-xs} {
    .about-architecture-section {
      .architecture-header {
        .title-box {
          font-size: 20px;
          letter-spacing: 6px;
        }
      }
      .architecture-main {
        padding: 6%;
      }
    }
  }
}
