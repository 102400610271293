@import '../assets/style/shared.scss';

.Company {
  @extend .tp-page-layout;
  position: relative;
  background: url('../assets/background/bg-company.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 8%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .company-container-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .company-container {
      width: 42%;

      .company-name {
        font-family: Roboto, sans-serif;
        font-size: 28px;
        font-weight: 300;
        line-height: 2.14;
        letter-spacing: 5.6px;
        text-align: left;
        color: #ffd133;
      }
      .company-title {
        font-size: 60px;
        font-weight: 300;
        line-height: 1.47;
        letter-spacing: -1.5px;
        text-align: left;
        color: #fff;
        white-space: pre;

        b {
          font-weight: 500;
        }
      }
      .horizontal-line {
        width: 40px;
        height: 1px;
        margin: 40px 0px;
        background-color: white;
      }
      .company-content {
        font-size: 20px;
        line-height: 1.8;
        text-align: left;
        color: rgba(255, 255, 255, 0.9);
        white-space: pre-wrap;
      }
    }
  }

  @media #{$mat-gt-xl} {
    .company-container-wrapper {
      .company-container {
        .company-name {
          font-size: toViewPort(28);
          letter-spacing: toViewPort(5.6);
        }
        .company-title {
          font-size: toViewPort(60);
          letter-spacing: toViewPort(-1.5);
        }
        .horizontal-line {
          width: toViewPort(40);
          height: toViewPort(1);
          margin: toViewPort(40) 0px;
        }
        .company-content {
          font-size: toViewPort(20);
        }
      }
    }
  }
  @media #{$mat-lt-xl} {
    .company-container-wrapper {
      .company-container {
        .company-name {
          font-size: 20px;
        }
        .company-title {
          font-size: 45px;
        }
        .horizontal-line {
          width: 30px;
          margin: 30px 0px;
        }
        .company-content {
          font-size: 14px;
        }
      }
    }
  }
  @media #{$mat-lt-lg} {
    .company-container-wrapper {
      .company-container {
        .company-name {
          font-size: 16px;
        }
        .company-title {
          font-size: 30px;
        }
        .horizontal-line {
          width: 20px;
          margin: 20px 0px;
        }
        .company-content {
          font-size: 12px;
        }
      }
    }
  }
  @media #{$mat-lt-md} {
    padding: 6%;

    .company-container-wrapper {
      .company-container {
        width: 100%;
        .company-title {
          font-size: 24px;
        }
      }
    }
  }
}
