@import '../../assets/style/constant.scss';

.tp-page-layout {
  width: 100vw;
  height: 100vh;

  @media #{$mat-md} and (orientation: portrait) {
    height: 720px;
  }
  @media #{$mat-lg} and (orientation: portrait) {
    height: 1080px;
  }
  @media #{$mat-gt-xl} and (orientation: portrait) {
    height: calc(9 / 16 * 100vw);
  }
  @media #{$mat-lt-md} {
    height: fit-content;
    min-height: 480px;
  }
}

@function toViewPort($base) {
  @return ($base / 19.2) * 1vw;
}

@function toFlex($first, $base) {
  @return ($first - ($base / 19.2)) * 1vw;
}

@function toViewPortMobile($base) {
  @return ($base / 3.6) * 1vw;
}
